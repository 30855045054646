import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 604.000000 442.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,442.000000) scale(0.100000,-0.100000)">

<path d="M4581 2638 c-68 -35 -86 -66 -86 -148 0 -54 4 -75 21 -96 63 -84 175
-98 256 -31 l27 22 -28 32 -29 32 -36 -19 c-20 -10 -45 -16 -56 -13 -51 13
-67 94 -27 131 29 27 43 27 85 2 l32 -20 30 33 c16 17 30 35 30 39 0 15 -79
50 -125 55 -39 3 -59 -1 -94 -19z"/>
<path d="M4905 2648 c-42 -15 -82 -55 -96 -96 -15 -46 -6 -127 18 -160 26 -37
99 -72 148 -72 50 0 122 35 149 73 16 22 21 44 21 97 0 82 -18 113 -85 148
-47 24 -105 27 -155 10z m101 -86 c57 -38 34 -152 -31 -152 -31 0 -65 44 -65
83 0 53 58 95 96 69z"/>
<path d="M408 2492 l-66 -157 51 -3 c43 -3 54 0 66 17 11 17 24 21 72 21 49 0
59 -3 64 -20 5 -17 15 -20 66 -20 l59 0 -16 38 c-8 20 -33 80 -54 132 -65 162
-55 150 -119 150 l-56 0 -67 -158z m136 14 c3 -13 9 -31 12 -40 5 -13 -1 -16
-25 -16 -35 0 -36 1 -19 47 14 39 24 42 32 9z"/>
<path d="M740 2490 l0 -160 120 0 120 0 0 45 0 45 -65 0 -65 0 0 115 0 115
-55 0 -55 0 0 -160z"/>
<path d="M1050 2537 c49 -92 60 -123 60 -160 l0 -47 55 0 55 0 0 53 c0 44 9
69 55 157 30 57 55 106 55 107 0 2 -24 3 -53 3 l-53 0 -27 -55 c-15 -30 -30
-55 -35 -55 -4 0 -20 25 -35 55 l-27 55 -55 0 -56 0 61 -113z"/>
<path d="M1372 2505 c-34 -79 -65 -151 -68 -160 -5 -12 4 -15 49 -15 47 0 57
3 62 20 5 17 15 20 70 20 55 0 65 -3 70 -20 5 -17 15 -20 65 -20 l58 0 -67
160 -66 160 -55 0 -55 0 -63 -145z m136 -22 c11 -32 10 -33 -19 -33 -32 0 -34
3 -18 49 13 36 21 32 37 -16z"/>
<path d="M1697 2643 c-3 -5 -6 -77 -5 -161 l0 -152 49 0 49 0 1 83 1 82 25
-67 c26 -68 26 -68 64 -68 34 0 39 3 45 28 4 15 15 45 25 67 l19 40 0 -82 0
-83 48 0 47 0 -2 148 c-1 81 -2 153 -2 160 -1 9 -16 12 -57 10 l-56 -3 -30
-72 c-17 -40 -34 -73 -38 -73 -4 0 -21 33 -38 73 l-30 72 -55 3 c-30 2 -58 -1
-60 -5z"/>
<path d="M2156 2497 c-36 -84 -66 -156 -66 -160 0 -4 24 -7 54 -7 46 0 56 3
61 20 5 17 15 20 70 20 54 0 63 -3 68 -20 4 -17 14 -20 62 -20 50 0 56 2 51
18 -4 9 -32 81 -64 159 l-57 143 -56 0 -56 0 -67 -153z m143 -34 c1 -8 -11
-13 -28 -13 l-30 0 16 47 16 47 13 -34 c7 -19 13 -41 13 -47z"/>
<path d="M2480 2490 l0 -160 55 0 55 0 0 60 0 60 50 0 50 0 0 -60 0 -60 55 0
55 0 0 160 0 160 -55 0 -55 0 0 -60 0 -60 -50 0 -50 0 0 60 0 60 -55 0 -55 0
0 -160z"/>
<path d="M2840 2490 l0 -160 51 0 51 0 -4 75 c-2 41 -1 75 2 75 3 0 32 -34 64
-75 57 -73 60 -75 102 -75 l44 0 0 160 0 160 -45 0 c-50 0 -49 3 -46 -87 1
-35 -2 -63 -6 -63 -5 0 -35 34 -66 75 -57 73 -59 75 -103 75 l-44 0 0 -160z"/>
<path d="M3262 2608 l3 -43 48 -3 47 -3 0 -115 0 -114 53 0 52 0 -3 114 -3
115 48 3 48 3 3 43 3 42 -151 0 -151 0 3 -42z"/>
<path d="M3580 2490 l0 -160 140 0 140 0 0 40 0 40 -85 0 c-78 0 -85 2 -85 20
0 18 7 20 75 20 l75 0 0 40 0 40 -75 0 c-68 0 -75 2 -75 20 0 18 7 20 80 20
l80 0 0 40 0 40 -135 0 -135 0 0 -160z"/>
<path d="M3900 2490 l0 -160 125 0 125 0 0 45 0 45 -70 0 -70 0 0 115 0 115
-55 0 -55 0 0 -160z"/>
<path d="M4180 2490 l0 -160 140 0 140 0 0 40 0 40 -85 0 c-78 0 -85 2 -85 20
0 18 7 20 75 20 l75 0 0 40 0 40 -75 0 c-68 0 -75 2 -75 20 0 18 7 20 85 20
l85 0 0 40 0 40 -140 0 -140 0 0 -160z"/>
<path d="M5180 2490 l0 -160 50 0 49 0 3 78 3 77 23 -63 c23 -63 24 -63 60
-60 35 3 37 6 62 68 l25 65 3 -82 3 -83 44 0 45 0 0 160 0 161 -57 -3 -57 -3
-31 -72 c-17 -40 -34 -75 -36 -78 -3 -3 -20 31 -38 75 l-33 80 -59 0 -59 0 0
-160z"/>
<path d="M5196 2158 c-14 -24 -42 -95 -64 -158 -21 -63 -44 -126 -51 -139 -7
-13 -8 -21 -3 -17 6 4 8 -8 5 -34 -4 -31 -2 -41 11 -46 17 -7 66 30 66 49 0 6
-11 0 -25 -13 -24 -23 -25 -23 -25 -3 0 40 29 116 69 176 73 113 100 217 59
225 -11 2 -25 -12 -42 -40z m35 -35 c-12 -46 -70 -167 -77 -160 -5 5 45 156
63 190 21 41 28 26 14 -30z"/>
<path d="M4183 2148 c-11 -14 -36 -94 -82 -266 -36 -130 -35 -122 -18 -122 6
0 25 22 40 50 15 27 37 55 48 60 23 13 24 6 4 -50 -8 -23 -12 -46 -9 -51 9
-15 40 -10 57 9 24 26 21 39 -3 17 -28 -25 -33 -15 -15 29 27 65 -8 103 -50
56 -10 -11 -20 -18 -22 -16 -2 2 15 65 37 141 22 76 40 142 40 147 0 12 -16
10 -27 -4z"/>
<path d="M5372 2093 c-12 -38 -29 -95 -38 -128 -17 -58 -19 -61 -46 -57 -39 6
-111 -64 -106 -104 5 -40 32 -53 67 -33 25 14 29 15 37 1 12 -19 50 -8 65 19
10 20 10 20 -15 4 -25 -16 -26 -16 -26 1 0 11 20 84 44 164 54 183 58 200 47
200 -5 0 -18 -30 -29 -67z m-72 -213 c0 -18 -27 -64 -49 -84 -16 -14 -24 -17
-33 -8 -15 15 -2 57 24 82 23 21 58 27 58 10z"/>
<path d="M3336 2022 c-2 -4 -7 -24 -11 -45 -4 -21 -14 -41 -22 -44 -8 -3 -11
-13 -8 -24 4 -11 -1 -42 -10 -70 -9 -27 -14 -56 -11 -64 8 -23 40 -18 66 10
26 28 17 34 -12 8 -27 -25 -30 -12 -10 53 14 46 22 60 40 62 30 5 28 22 -3 22
l-24 0 14 48 c8 26 15 48 15 50 0 5 -19 1 -24 -6z"/>
<path d="M4047 2023 c-3 -5 -8 -23 -12 -41 -3 -18 -14 -40 -23 -49 -11 -11
-13 -18 -5 -20 8 -3 5 -22 -9 -64 -11 -33 -17 -66 -14 -74 8 -23 40 -18 66 10
26 28 17 34 -12 8 -27 -24 -29 -14 -10 52 14 47 23 61 40 63 30 5 28 22 -3 22
l-24 0 14 48 c8 26 15 48 15 50 0 5 -19 1 -23 -5z"/>
<path d="M3422 1966 c1 -10 8 -17 13 -17 15 1 12 24 -3 29 -9 3 -13 -2 -10
-12z"/>
<path d="M2374 1890 c-29 -12 -64 -58 -64 -84 0 -27 18 -46 42 -46 22 0 68 28
68 41 0 5 -11 2 -25 -7 -50 -33 -74 9 -34 61 18 22 27 26 51 21 21 -4 28 -2
26 7 -6 15 -36 19 -64 7z"/>
<path d="M2485 1876 c-48 -48 -44 -108 9 -114 55 -7 111 89 74 126 -19 19 -57
14 -83 -12z m73 -17 c4 -20 -44 -79 -64 -79 -21 0 -27 25 -13 52 24 46 70 62
77 27z"/>
<path d="M2645 1888 c-6 -20 -35 -121 -35 -125 0 -2 6 -3 13 -3 6 0 24 20 39
43 53 84 97 104 58 27 -12 -23 -19 -48 -16 -56 8 -20 40 -17 59 4 24 26 21 39
-3 17 -28 -26 -32 -16 -12 38 13 35 14 49 5 58 -18 18 -56 3 -78 -30 -23 -35
-30 -34 -16 2 7 17 7 28 1 32 -6 4 -12 0 -15 -7z"/>
<path d="M2825 1888 c-6 -21 -35 -121 -35 -125 0 -2 6 -3 13 -3 7 0 23 17 34
38 24 42 70 88 79 80 3 -3 -4 -25 -16 -48 -12 -23 -19 -48 -16 -56 8 -20 40
-17 59 4 24 26 21 39 -3 17 -28 -26 -32 -15 -14 33 30 75 -8 98 -65 40 l-32
-33 10 28 c13 35 14 37 1 37 -6 0 -12 -6 -15 -12z"/>
<path d="M3020 1871 c-31 -30 -46 -71 -36 -96 9 -23 52 -18 83 11 l28 26 -28
-17 c-16 -9 -36 -14 -45 -10 -13 5 -8 11 22 28 45 26 62 48 53 71 -10 25 -45
19 -77 -13z m37 -37 c-38 -32 -51 -30 -32 5 16 33 35 43 50 29 7 -6 0 -18 -18
-34z"/>
<path d="M3184 1890 c-32 -13 -65 -61 -62 -88 5 -39 31 -49 72 -29 20 9 36 21
36 27 0 6 -11 3 -25 -6 -50 -33 -74 9 -34 61 18 22 27 26 51 21 21 -4 28 -2
26 7 -6 15 -36 19 -64 7z"/>
<path d="M3385 1848 c-18 -61 -19 -75 -5 -83 13 -8 60 21 60 37 0 6 -9 3 -20
-7 -21 -19 -25 -10 -11 27 20 54 22 78 7 78 -10 0 -21 -20 -31 -52z"/>
<path d="M3505 1888 c-6 -20 -35 -121 -35 -125 0 -2 6 -3 13 -3 6 0 24 20 39
43 30 48 65 83 74 75 2 -3 -5 -26 -18 -52 -17 -36 -19 -49 -10 -58 10 -10 18
-10 39 2 15 8 29 22 31 30 4 11 -1 10 -17 -4 -29 -27 -35 -16 -15 33 29 74 -5
95 -64 39 l-33 -33 10 27 c7 18 7 29 1 33 -6 4 -12 0 -15 -7z"/>
<path d="M3717 1882 c-52 -35 -72 -83 -45 -110 18 -18 39 -15 65 10 20 19 23
19 23 4 0 -9 -23 -41 -51 -71 -53 -56 -63 -96 -26 -103 27 -5 54 24 72 79 9
26 27 64 41 84 28 41 29 46 11 39 -10 -4 -12 3 -9 26 6 41 -7 37 -43 -13 -35
-48 -65 -60 -65 -27 0 33 53 80 89 80 17 0 31 5 31 10 0 17 -63 11 -93 -8z m6
-208 c-19 -39 -43 -57 -43 -31 0 17 50 81 57 74 2 -3 -4 -22 -14 -43z"/>
<path d="M4312 1884 c-26 -18 -52 -65 -52 -93 0 -41 67 -37 101 6 13 17 13 17
-14 0 -16 -10 -36 -16 -45 -12 -13 5 -8 11 22 28 45 26 62 48 53 71 -8 20 -36
21 -65 0z m25 -50 c-38 -32 -51 -30 -32 5 16 33 35 43 50 29 7 -6 0 -18 -18
-34z"/>
<path d="M4556 1891 c-3 -4 -8 -34 -12 -64 -6 -48 -4 -58 10 -63 11 -4 26 2
40 15 23 22 23 22 29 2 7 -28 38 -27 64 2 39 42 67 117 44 117 -5 0 -17 -23
-27 -52 -18 -50 -46 -80 -60 -65 -6 6 5 57 23 103 3 8 -1 14 -8 14 -8 0 -27
-24 -42 -54 -47 -89 -75 -80 -42 13 12 35 12 41 0 41 -8 0 -16 -4 -19 -9z"/>
<path d="M4805 1876 c-48 -48 -44 -108 9 -114 55 -7 111 89 74 126 -19 19 -57
14 -83 -12z m73 -17 c4 -20 -44 -79 -64 -79 -21 0 -27 25 -13 52 24 46 70 62
77 27z"/>
<path d="M4981 1873 c-21 -26 -22 -27 -15 -4 4 17 2 22 -7 19 -13 -4 -21 -25
-33 -90 -10 -57 13 -48 49 18 25 47 31 52 35 33 5 -19 7 -20 18 -6 17 20 12
51 -9 55 -9 2 -26 -10 -38 -25z"/>
<path d="M5410 1775 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5490 1775 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
<path d="M5560 1775 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
